(function () {
'use strict';

angular.module('aerosApp').
factory('ToolModelPrototype', ToolModelPrototype);

ToolModelPrototype.$inject = ['$injector']
function ToolModelPrototype ($injector) {
  return function(resource) {
    return {
      destroy : deleteToolLibrary,
      update  : updateToolLibrary
    };

    function deleteToolLibrary () {
      return resource.
        delete({ orgId: this.organizationId, toolId: this.uuid }).
          $promise;
    }

    function updateToolLibrary () {
      return resource.
        update({ orgId: this.organizationId, toolId: this.uuid }, this).
          $promise;
    }
  };
}

}());